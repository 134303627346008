import styled, { css } from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import Grid, {
  GridItem,
  gridPosition,
} from 'components/atoms/grid-container/next'
import Text, {
  SIZE as TEXT_SIZE,
} from '@typeform/ginger/dist/components/typography/text/next'
import Headline, {
  SIZE as HEADLINE_SIZE,
} from '@typeform/ginger/dist/components/typography/headline/next'
import ModuleMediaContainer from 'components/atoms/module-media-container'

import {
  HeroLayout,
  TBackgroundContainerProps,
  THeroActionBarProps,
  THeroContainerProps,
  THeroGridItemProps,
} from './hero-module.types'

export const HeroContainer = styled.div<THeroContainerProps>`
  position: relative;
  color: ${({ palette }) =>
    palette === Palette.Negative ? NeutralColors.White : NeutralColors.Ink};
  padding-top: ${spacing(8)};
  padding-bottom: ${spacing(8)};

  ${({ layout }) =>
    layout === HeroLayout.MediaCenter &&
    css`
      ${mediaQueries.md`
          padding-top: ${spacing(9)};
          padding-bottom: ${spacing(9)};
        `}

      ${mediaQueries.lg`
          padding-top: ${spacing(10)};
          padding-bottom: ${spacing(10)};
        `}

        ${mediaQueries.xl`
          padding-top: ${spacing(12)};
          padding-bottom: ${spacing(12)};
        `}
    `}

  ${({ layout }) =>
    layout !== HeroLayout.MediaCenter &&
    css`
      ${mediaQueries.md`
        padding-top: ${spacing(5)};
        padding-bottom: ${spacing(5)};
      `}

      ${mediaQueries.xl`
        padding-top: ${spacing(6)};
        padding-bottom: ${spacing(6)};
      `}
    `}
`

export const BackgroundContainer = styled.div<TBackgroundContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${({ palette, backgroundColor }) =>
    backgroundColor ||
    (palette === Palette.Negative ? NeutralColors.Ink : NeutralColors.White)};
  z-index: -1;

  ${({ hasMedia, useHalfwayBackgroundForMobile }) =>
    hasMedia &&
    useHalfwayBackgroundForMobile &&
    css`
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      height: 75%;

      ${mediaQueries.sm`
        height: 70%;
      `}

      ${mediaQueries.lg`
        height: 60%;
      `}
    `}

  ${({ layout }) =>
    layout !== HeroLayout.MediaCenter &&
    mediaQueries.md`
    border-radius: 0;
    height: 100%;
  `}
`

export const HeroGrid = styled(Grid)`
  row-gap: ${spacing(5)};

  ${mediaQueries.sm`
    row-gap: ${spacing(8)};
  `}
  ${mediaQueries.md`
    row-gap: ${spacing(9)};
  `}
  ${mediaQueries.xl`
    row-gap: ${spacing(8)};
  `};
`

const MediaGridPositons = {
  [HeroLayout.MediaLeft]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().start(1).size(6),
  },
  [HeroLayout.MediaRight]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().end(-1).size(6),
  },
  [HeroLayout.MediaCenter]: gridPosition().fill(),
}

export const ContentGridPositions = {
  [HeroLayout.MediaLeft]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().end(-1).size(6),
  },
  [HeroLayout.MediaRight]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().start(1).size(6),
  },
  [HeroLayout.MediaCenter]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().pad(3),
  },
}

export const MediaContainerGridItem = styled(
  ModuleMediaContainer
).attrs<THeroGridItemProps>(({ layout, ...props }) => ({
  ...props,
  forwardedAs: GridItem,
  column: MediaGridPositons[layout],
}))<THeroGridItemProps>`
  ${({ layout }) =>
    layout === HeroLayout.MediaLeft &&
    css`
      ${mediaQueries.md`
        margin-left: -${spacing(3)};
      `}
      ${mediaQueries.lg`
        margin-left: -${spacing(5)};
      `}
      ${mediaQueries.xl`
        margin-left: -${spacing(6)};
      `}
    `}

  ${({ layout }) =>
    layout !== HeroLayout.MediaCenter &&
    css`
      ${mediaQueries.md`
        width: calc(100% + ${spacing(3)});
      `}
      ${mediaQueries.lg`
        width: calc(100% + ${spacing(5)});
      `}
      ${mediaQueries.xl`
        width: calc(100% + ${spacing(6)});
      `}
    `}
`

export const ContentContainerGridItem = styled(
  GridItem
).attrs<THeroGridItemProps>(({ layout, ...props }) => ({
  ...props,
  column: ContentGridPositions[layout],
}))<THeroGridItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  grid-row: 1;

  ${({ layout }) =>
    layout !== HeroLayout.MediaCenter &&
    mediaQueries.md`
    text-align: left;
    align-items: flex-start;
  `}
`

export const Eyebrow = styled(Text).attrs({ size: TEXT_SIZE.XS })`
  text-transform: uppercase;
  margin-bottom: ${spacing(3)};
`

export const Title = styled(Headline).attrs({
  extraSmallViewportsSize: HEADLINE_SIZE.THREE,
  smallViewportsSize: HEADLINE_SIZE.THREE,
  mediumViewportsSize: HEADLINE_SIZE.FOUR,
  largeViewportsSize: HEADLINE_SIZE.FIVE,
})``

export const Body = styled(Text).attrs({
  extraSmallViewportsSize: TEXT_SIZE.M,
  smallViewportsSize: TEXT_SIZE.M,
  mediumViewportsSize: TEXT_SIZE.L,
  largeViewportsSize: TEXT_SIZE.L,
})`
  margin-top: ${spacing(4)};
`

export const ActionBarContainer = styled.div<THeroActionBarProps>`
  &:not(:empty) {
    ${({ isBelowMedia, isMediaCenter }) => css`
      margin-top: ${spacing(isBelowMedia ? 0 : 7)};

      ${isBelowMedia &&
      css`
        grid-column: 1 / -1;
        justify-self: center;
      `}

      ${mediaQueries.sm`
        margin-top: ${spacing(isBelowMedia ? 0 : 5)};
      `}

      ${mediaQueries.md`
        margin-top: ${spacing(isMediaCenter && isBelowMedia ? 0 : 5)};
      `}
    `}
  }
`
