import { cssForBreakpointsConfig } from '@typeform/ginger/dist/util/mediaqueries'

import { TGridPositionConfig } from './types'

export const getGridPositionsFor = (
  positionType: 'row' | 'column',
  { start, end, base, ...breakpointConfigs }: TGridPositionConfig
) => cssForBreakpointsConfig({
  base: {
    start: start || base?.start,
    end: end || base?.end,
  },
  ...breakpointConfigs,
})`
  ${({ start, end }: TGridPositionConfig) => `
    ${start && `grid-${positionType}-start: ${start};`}
    ${end && `grid-${positionType}-end: ${end};`}
  `}
`

export const gridPosition = () => {
  let _start = 0
  let _end = 0

  const create = () => {
    return {
      start: _start,
      end: _end,
    }
  }

  const gridPositionHelperApi = {
    start(newStart: number) {
      _start = newStart
      return gridPositionHelperApi
    },
    end(newEnd: number) {
      _end = newEnd
      return gridPositionHelperApi
    },
    fill() {
      if (!_start && !_end) {
        _start = 1
        _end = -1
      } else if (_start) {
        _end = -1
      } else if (_end) {
        _start = 1
      }

      return create()
    },
    size(newSize: number) {
      if (!_start && !_end) {
        throw Error(
          'gridPosition size() should be called after start() or end()'
        )
      }

      if (_start) {
        _end = _start > 0 ? _start + newSize : _start - newSize
      } else if (_end) {
        _start = _end - newSize
      }

      return create()
    },
    pad(unit: number) {
      _start = unit
      _end = unit * -1
      return create()
    },
  }

  return gridPositionHelperApi
}
