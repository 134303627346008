import PropTypes from 'prop-types'

export const SIZE = {
  ZERO: 'size0',
  ONE: 'size1',
  TWO: 'size2',
  THREE: 'size3',
  FOUR: 'size4',
  FIVE: 'size5',
  SIX: 'size6',
}

export const SIZES = Object.values(SIZE)

export const FONT_SIZE = {
  [SIZE.ZERO]: '24px',
  [SIZE.ONE]: '30px',
  [SIZE.TWO]: '36px',
  [SIZE.THREE]: '48px',
  [SIZE.FOUR]: '64px',
  [SIZE.FIVE]: '80px',
  [SIZE.SIX]: '112px',
}

export const LETTER_SPACING = {
  [SIZE.ZERO]: '-0.02em',
  [SIZE.ONE]: '-0.02em',
  [SIZE.TWO]: '-0.02em',
  [SIZE.THREE]: '-0.02em',
  [SIZE.FOUR]: '-0.02em',
  [SIZE.FIVE]: '-0.02em',
  [SIZE.SIX]: '-0.03em',
}

export const LINE_HEIGHT = {
  [SIZE.ZERO]: '1.2',
  [SIZE.ONE]: '1.1',
  [SIZE.TWO]: '1.1',
  [SIZE.THREE]: '1.1',
  [SIZE.FOUR]: '1.1',
  [SIZE.FIVE]: '1',
  [SIZE.SIX]: '1',
}

export const propTypes = {
  children: PropTypes.node,
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  size: PropTypes.oneOf(SIZES),
  extraSmallViewportsSize: PropTypes.oneOf(SIZES),
  smallViewportsSize: PropTypes.oneOf(SIZES),
  mediumViewportsSize: PropTypes.oneOf(SIZES),
  largeViewportsSize: PropTypes.oneOf(SIZES),
}

export const defaultProps = {
  element: 'h1',
  size: SIZE.THREE,
  extraSmallViewportsSize: null,
  smallViewportsSize: null,
  mediumViewportsSize: null,
  largeViewportsSize: null,
}
