import React, { memo, ReactNode, useCallback } from 'react'
import { IntersectionObserverProps, InView } from 'react-intersection-observer'
import { trackHomepageSectionInView } from 'components/tracking'

export enum TrackingLocationNames {
  Header = 'header',
  MediaTextSection1 = 'body:section-1',
  MediaTextSection2 = 'body:section-2',
  CtaSection = 'body:section-3',
  ContentGrid = 'body:section-4',
  Stats = 'body:stats',
  Integrations = 'body:integrations',
  Ending = 'body:ending',
}

const TrackingLocation = ({
  children,
  inViewThreshold,
  name,
}: {
  name: TrackingLocationNames
  children?: ReactNode
  inViewThreshold?: IntersectionObserverProps['threshold']
}) => {
  const handleInViewSection = useCallback(
    (inView: boolean) => {
      if (inView && name) {
        const documentHeight = document.body.offsetHeight - window.innerHeight
        const scrollPercentage = window.scrollY / documentHeight

        trackHomepageSectionInView({
          location: name,
          scrollPercentage: `${Math.round(scrollPercentage * 100)}%`,
        })
      }
    },
    [name]
  )

  return (
    <InView
      triggerOnce
      threshold={inViewThreshold}
      onChange={handleInViewSection}
    >
      {({ ref }) => <div ref={ref}>{children}</div>}
    </InView>
  )
}

TrackingLocation.displayName = 'TrackingLocation'

export default memo(TrackingLocation)
