import get from 'lodash.get'
import PropTypes from 'prop-types'
import isMobile from 'ismobilejs'

// This exists as a TS type in `tracking-types.ts`
export const trackingPropType = PropTypes.shape({
  item: PropTypes.string,
  item_type: PropTypes.string,
  category: PropTypes.string,
  typeform_version: PropTypes.string,
  typeform_property: PropTypes.string,
  label: PropTypes.string,
  unique_pageview_id: PropTypes.string,
  unique_sectionview_id: PropTypes.string,
  attribution_user_id: PropTypes.string,
  page: PropTypes.string,
  tracking_session_id: PropTypes.string,
})

// This exists as a TS type in `tracking-types.ts`
export const experimentTrackingPropType = PropTypes.shape({
  eventName: PropTypes.string,
})

export function setTrackingProperties(tracking) {
  const isMobileDevice = isMobile(window.navigator).any
  const properties = { ...tracking }

  properties.typeform_version = isMobileDevice ? 'mobile' : 'v2'

  return properties
}

export function onTrackItemClickEvent(
  config,
  trackEvent,
  trackExperimentEvent
) {
  const tracking = get(config, 'tracking')
  const experimentTracking = get(config, 'experimentTracking')

  if (tracking) {
    const properties = setTrackingProperties(tracking)
    trackEvent('item_clicked', {
      ...properties,
      link_url: get(config, 'url'),
      location: properties.location || document.location.href,
    })
  }

  if (trackExperimentEvent && experimentTracking) {
    trackExperimentEvent({
      name: experimentTracking.eventName,
    })
  }
}
