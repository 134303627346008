import styled from 'styled-components'
import {
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '@typeform/ginger/dist/constants/design'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import HeroModule from 'components/modules/hero-module'
import { spacing } from '@typeform/ginger/dist/util/spacing'

export const HeaderSpaceWrapper = styled.section`
  padding-top: ${MOBILE_HEADER_HEIGHT}px;

  ${mediaQueries.md`
    padding-top: ${HEADER_HEIGHT}px;
  `}
`

export const MediaContainer = styled.div`
  aspect-ratio: 1/1;

  ${mediaQueries.md`
    margin-right: ${spacing(4)};
  `}

  > * {
    width: 100%;
    height: auto;
  }
`

// TODO: Will be removed when global approach for all site is implemented for text-wrap strategy
// See: https://typeform.atlassian.net/browse/WEB-7208
export const StyledHeroModule = styled(HeroModule)`
  h1 {
    text-wrap: balance;
  }
`
