import React, { memo } from 'react'
type TRenderHTMLProps = {
  as?: React.ElementType | string | React.ReactNode
  html: string | React.ReactNode
}

const RenderHTML: React.FC<TRenderHTMLProps> = ({
  as: Component = 'div',
  html,
  ...rest
}) => {
  if (typeof Component === 'string') {
    const ComponentElement = Component
    return (
      <ComponentElement
        dangerouslySetInnerHTML={{ __html: html }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(rest as any)}
      />
    )
  }
  return null
}

export default memo(RenderHTML)
