import React, { Suspense, memo } from 'react'
import ErrorBoundary from 'components/error-boundary'
import SuspenseFallback from 'components/suspense-fallback'
import dynamic from 'next/dynamic'

import HeroSection from './hero-section'
import TrackingLocation, {
  TrackingLocationNames,
} from './common/tracking-location'

const Partners = dynamic(() => import('./partners-section'))
const HomepageBelowFold = dynamic(() => import('./homepage-below-fold'))

const RefreshedHomepage = () => {
  return (
    <>
      <TrackingLocation name={TrackingLocationNames.Header}>
        <ErrorBoundary>
          <HeroSection />
        </ErrorBoundary>

        <ErrorBoundary>
          <Suspense fallback={<SuspenseFallback />}>
            <Partners />
          </Suspense>
        </ErrorBoundary>
      </TrackingLocation>

      <Suspense fallback={<SuspenseFallback />}>
        <HomepageBelowFold />
      </Suspense>
    </>
  )
}

RefreshedHomepage.displayName = 'RefreshedHomepage'

export default memo(RefreshedHomepage)
