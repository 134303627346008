import { useCallback, useMemo } from 'react'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import { Device } from '@typeform/ginger/dist/constants/device'
import { onTrackItemClickEvent } from '@typeform/ginger/dist/util/tracking'
import { ButtonSize } from 'components/atoms/button'
import ActionBar, {
  TActionBarMouseEventHandler,
} from 'components/molecules/action-bar'
import RenderHtml from 'components/atoms/render-html'
import useMediaWithDevice from '@typeform/ginger/dist/hooks/use-media-with-device'

import {
  ActionBarContainer,
  BackgroundContainer,
  Body,
  ContentContainerGridItem,
  Eyebrow,
  HeroContainer,
  HeroGrid,
  MediaContainerGridItem,
  Title,
} from './hero-module.styles'
import {
  ActionBarPosition,
  HeroLayout,
  THeroModuleProps,
} from './hero-module.types'
import { defaultProps, testIds } from './hero-module.constants'

const HeroModule = ({
  layout: baseLayout,
  backgroundColor,
  useHalfwayBackgroundForMobile,
  palette,
  eyebrow,
  title,
  body,
  actionBar,
  customActionBar,
  actionBarPosition,
  media,
  smallMedia,
  trackEvent,
  trackExperimentEvent,
  ...rest
}: THeroModuleProps) => {
  const isSmallScreen = useMediaWithDevice(
    `(max-width: ${BREAKPOINTS.md - 1}px)`,
    Device.Mobile
  )

  const isBelowMedia = actionBarPosition === ActionBarPosition.BelowMedia
  const isMediaCenter = baseLayout === HeroLayout.MediaCenter

  const handleActionClick: TActionBarMouseEventHandler = useCallback(
    ({ link, event }) => {
      onTrackItemClickEvent(link, trackEvent, trackExperimentEvent)
      actionBar?.onClick?.({ link, event })
    },
    [trackEvent, trackExperimentEvent, actionBar]
  )

  const layout = useMemo(
    () => (!media ? HeroLayout.MediaCenter : baseLayout),
    [baseLayout, media]
  )

  const shouldShowActionsBelowMedia = useMemo(() => {
    if (!isBelowMedia || !media) {
      return false
    }

    if (layout === HeroLayout.MediaCenter) {
      return true
    }

    return isSmallScreen
  }, [isBelowMedia, media, layout, isSmallScreen])

  const renderedTitle = useMemo(() => {
    if (!title) {
      return null
    }

    if (typeof title === 'string') {
      return <RenderHtml as='span' html={title} />
    }

    return title
  }, [title])

  const renderedBody = useMemo(() => {
    if (!body) {
      return null
    }

    if (typeof body === 'string') {
      return <RenderHtml as='span' html={body} />
    }
    return body
  }, [body])

  const actions = useMemo(() => {
    if (!customActionBar && !actionBar) {
      return null
    }

    return (
      <ActionBarContainer
        isBelowMedia={isBelowMedia}
        isMediaCenter={isMediaCenter}
      >
        {customActionBar ?? (
          <ActionBar
            {...actionBar}
            data-testid={testIds.ActionBar}
            size={ButtonSize.Large}
            palette={palette}
            onClick={handleActionClick}
          />
        )}
      </ActionBarContainer>
    )
  }, [
    actionBar,
    customActionBar,
    handleActionClick,
    isBelowMedia,
    isMediaCenter,
    palette,
  ])

  return (
    <HeroContainer
      data-testid={testIds.Container}
      data-qa={testIds.Container}
      layout={layout}
      palette={palette}
      {...rest}
    >
      <BackgroundContainer
        data-testid={testIds.Background}
        palette={palette}
        backgroundColor={backgroundColor}
        useHalfwayBackgroundForMobile={useHalfwayBackgroundForMobile}
        layout={layout}
        hasMedia={!!media}
      />
      <HeroGrid>
        {media && (
          <MediaContainerGridItem
            data-testid={testIds.Media}
            layout={layout}
            media={media}
            smallMedia={smallMedia}
          />
        )}
        <ContentContainerGridItem data-testid={testIds.Content} layout={layout}>
          {eyebrow && (
            <Eyebrow data-testid={testIds.Eyebrow}>{eyebrow}</Eyebrow>
          )}
          <Title data-testid={testIds.Title}>{renderedTitle}</Title>
          {renderedBody && (
            <Body data-testid={testIds.Body}>{renderedBody}</Body>
          )}
          {!shouldShowActionsBelowMedia && actions}
        </ContentContainerGridItem>
        {shouldShowActionsBelowMedia && actions}
      </HeroGrid>
    </HeroContainer>
  )
}

HeroModule.defaultProps = defaultProps

export default HeroModule
