import { spacing } from '@typeform/ginger/dist/util/spacing'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing(3)};
  width: 100%;
  flex-direction: column;
  align-content: center;

  ${mediaQueries.xs`
    width: auto;
  `}

  ${mediaQueries.sm`
    flex-direction: row;
  `}
`
