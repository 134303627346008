import { Device } from '@typeform/ginger/dist/constants/device'
import { useMemo } from 'react'
import useMediaWithDevice from '@typeform/ginger/dist/hooks/use-media-with-device'

import { StyledModuleMediaContainer } from './module-media-container.styles'
import { TModuleMediaContainerProps } from './types'
import { defaultProps } from './constants'

const ModuleMediaContainer = ({
  media: bigMedia,
  smallMedia,
  thresholdBreakpoint = defaultProps.thresholdBreakpoint,
  matchSmallMediaByDefault,
  ...passThroughProps
}: TModuleMediaContainerProps) => {
  const isSmallScreen = useMediaWithDevice(
    `(max-width: ${thresholdBreakpoint - 1}px)`,
    Device.Mobile,
    matchSmallMediaByDefault
  )

  const media = useMemo(() => {
    if (smallMedia && isSmallScreen) {
      return smallMedia
    }

    return bigMedia
  }, [bigMedia, smallMedia, isSmallScreen])

  return (
    <StyledModuleMediaContainer {...passThroughProps}>
      {media}
    </StyledModuleMediaContainer>
  )
}

ModuleMediaContainer.defaultProps = defaultProps

export default ModuleMediaContainer
