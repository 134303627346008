import { DEFAULT_PALETTE } from '@typeform/ginger/dist/constants/palettes'

import { ActionBarPosition, HeroLayout } from './hero-module.types'

export const defaultProps = {
  layout: HeroLayout.MediaLeft,
  palette: DEFAULT_PALETTE,
  eyebrow: '',
  actionBar: null,
  actionBarPosition: ActionBarPosition.AboveMedia,
  useHalfwayBackgroundForMobile: false,
}

export const testIds = {
  Container: 'hero-module',
  Background: 'hero-module--background',
  Media: 'hero-module--media',
  Content: 'hero-module--content',
  Eyebrow: 'hero-module--eyebrow',
  Title: 'hero-module--title',
  Body: 'hero-module--body',
  ActionBar: 'hero-module--action-bar',
  CustomActionBar: 'hero-module--custom-action-bar',
}
