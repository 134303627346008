import { memo } from 'react'
import { Typography } from 'components/typography/typography.styles'

import {
  defaultProps,
  FONT_SIZE,
  LETTER_SPACING,
  LINE_HEIGHT,
  propTypes,
} from './constants'

export const Headline = ({ element, ...props }) => {
  return (
    <Typography
      as={element}
      {...props}
      fontSizes={FONT_SIZE}
      letterSpacings={LETTER_SPACING}
      lineHeights={LINE_HEIGHT}
    />
  )
}

Headline.propTypes = propTypes

Headline.defaultProps = defaultProps

export default memo(Headline)
