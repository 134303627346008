import { GRID_MAX_WIDTH } from '@typeform/ginger/dist/constants/design'
import mediaQueries, {
  cssForBreakpointsConfig,
} from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import styled, { css } from 'styled-components'

import { getGridPositionsFor } from './helpers'
import { TGridItemPropTypes, TGridPropTypes } from './types'

const Grid = styled.div<TGridPropTypes>`
  display: grid;
  margin: auto;
  grid-template-columns: repeat(4, 1fr);
  gap: ${spacing(2)};
  padding-right: ${spacing(2)};
  padding-left: ${spacing(2)};

  ${mediaQueries.xs`
    grid-template-columns: repeat(8, 1fr);
    gap: ${spacing(3)};
    padding-right: ${spacing(3)};
    padding-left: ${spacing(3)};
  `};

  ${mediaQueries.md`
    grid-template-columns: repeat(12, 1fr);
    gap: ${spacing(3)};
    padding-right: ${spacing(3)};
    padding-left: ${spacing(3)};
  `};

  ${mediaQueries.lg`
    grid-template-columns: repeat(12, 1fr);
    gap: ${spacing(3)};
    padding-right: ${spacing(5)};
    padding-left: ${spacing(5)};
  `};

  ${mediaQueries.xl`
    grid-template-columns: repeat(12, 1fr);
    gap: ${spacing(4)};
    padding-right: ${spacing(6)};
    padding-left: ${spacing(6)};
    max-width: ${GRID_MAX_WIDTH}px;
  `};

  ${({ rows }) => {
    if (!rows) {
      return
    }

    if (typeof rows === 'number') {
      return `grid-template-rows: repeat(${rows}, 1fr);`
    }

    if (typeof rows === 'string') {
      return `grid-template-rows: ${rows};`
    }

    return cssForBreakpointsConfig(rows)`
      ${(rowForBreakpoint: string | number) => {
        if (typeof rowForBreakpoint === 'number') {
          return `grid-template-rows: repeat(${rowForBreakpoint}, 1fr);`
        }

        return `grid-template-rows: ${rowForBreakpoint};`
      }}
    `
  }}

  ${({ template }) => {
    if (!template) {
      return
    }

    if (typeof template === 'string') {
      return `grid-template-areas: ${template};`
    }

    return cssForBreakpointsConfig(template)`
      grid-template-areas: ${(templateAreaForBreakpoint: string) =>
        templateAreaForBreakpoint};
    `
  }}
`

export const GridItem = styled.div<TGridItemPropTypes>`
  ${({ column, row, area }) => css`
    ${column && getGridPositionsFor('column', column)}
    ${row && getGridPositionsFor('row', row)}

    ${area && `grid-area: ${area};`}
  `}
`

export default Grid
