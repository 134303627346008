import styled from 'styled-components'

export const StyledModuleMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: auto;

  > * {
    width: 100%;
    height: auto;
  }
`
