import { ReactNode } from 'react'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import {
  TTrackEventFunction,
  TTrackExperimentEventFunction,
} from '@typeform/ginger/dist/util/tracking-types'
import { TActionBarProps } from 'components/molecules/action-bar'

export enum HeroLayout {
  MediaLeft = 'media-left',
  MediaRight = 'media-right',
  MediaCenter = 'media-center',
}
export enum ActionBarPosition {
  AboveMedia = 'above-media',
  BelowMedia = 'below-media',
}

export type THeroModuleProps = {
  layout: HeroLayout
  backgroundColor?: string
  useHalfwayBackgroundForMobile?: boolean
  palette: Palette
  eyebrow?: ReactNode
  title: ReactNode
  body: ReactNode
  actionBar?: TActionBarProps | null
  customActionBar?: ReactNode
  actionBarPosition?: ActionBarPosition
  media?: ReactNode
  smallMedia?: ReactNode
  trackEvent?: TTrackEventFunction
  trackExperimentEvent?: TTrackExperimentEventFunction
}

export type THeroContainerProps = {
  layout: HeroLayout
  palette: Palette
}

export type TBackgroundContainerProps = {
  layout: HeroLayout
  backgroundColor?: string
  palette?: Palette
  hasMedia?: boolean
  useHalfwayBackgroundForMobile?: boolean
}

export type THeroGridItemProps = {
  layout: HeroLayout
}

export type THeroActionBarProps = TActionBarProps & {
  isBelowMedia: boolean
  isMediaCenter: boolean
}
