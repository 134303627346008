import React, { MouseEvent, memo, useCallback } from 'react'
import Button, { ButtonVariant } from 'components/atoms/button'

import { Container } from './action-bar.styles'
import { TActionBarLinkProps, TActionBarProps } from './action-bar.types'
import { defaultProps } from './action-bar.constants'

const ActionBar = ({
  primaryLink,
  secondaryLink,
  tertiaryLink,
  size,
  palette,
  onClick,
  ...props
}: TActionBarProps) => {
  const shouldRender = Boolean(primaryLink || secondaryLink || tertiaryLink)

  const handleClick = useCallback<
    (
      link: TActionBarLinkProps
    ) => (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
  >(
    link => event => {
      onClick?.({ link, event })
    },
    [onClick]
  )

  if (!shouldRender) {
    return null
  }

  return (
    <Container data-qa='action-bar' {...props}>
      {primaryLink && (
        <Button
          palette={palette}
          size={size}
          onClick={handleClick(primaryLink)}
          {...primaryLink}
          variant={ButtonVariant.Primary}
        >
          {primaryLink.label}
        </Button>
      )}
      {secondaryLink && (
        <Button
          palette={palette}
          size={size}
          onClick={handleClick(secondaryLink)}
          {...secondaryLink}
          variant={ButtonVariant.Secondary}
        >
          {secondaryLink.label}
        </Button>
      )}
      {tertiaryLink && (
        <Button
          palette={palette}
          size={size}
          onClick={handleClick(tertiaryLink)}
          {...tertiaryLink}
          variant={ButtonVariant.Tertiary}
        >
          {tertiaryLink.label}
        </Button>
      )}
    </Container>
  )
}

ActionBar.defaultProps = defaultProps

export default memo(ActionBar)
